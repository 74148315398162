<template>
  <div>
    <vs-row class="heading-row">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <h1 class="heading">تقرير المعاملات المالية</h1>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-w="3">
        <p>المدفوع</p>
        <p>المستحق</p>
      </vs-col>
      <vs-col vs-w="3">
        <h6>{{ data["Transactions total amount"] }} د.ل</h6>
        <h6>233 د.ل</h6>
      </vs-col>

      <vs-col vs-w="3"  vs-justify="end">
        <p>من التاريخ</p>
        <p>الى التاريخ</p>
      </vs-col>
      <vs-col vs-w="3"  vs-justify="end">
        <h6>{{ from_date }}</h6>
        <h6>{{ to_date }}</h6>
      </vs-col>
    </vs-row>
    <vs-row class="order-items justify-content-end">
      <vs-col vs-align="center" vs-w="12">
        <vs-table hoverFlat :data="data" class="w-full order-table">
          <template slot="thead">
            <vs-th>رقم الفاتورة</vs-th>
            <vs-th>تاريخ المعاملة</vs-th>
            <vs-th>الرقم المرجعي</vs-th>
            <vs-th>حالة المعاملة</vs-th>
            <vs-th>المدفوع</vs-th>
            <vs-th>المستحق</vs-th>
            <vs-th>مجموع الطلبية</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr v-for="(tr, index) in data" :key="index">
              <vs-td> {{ tr.invoice.id }} </vs-td>
              <vs-td>{{ tr.date }}</vs-td>
              <vs-td> {{ tr.reference_number }}</vs-td>
              <vs-td>{{ tr.status }}</vs-td>
              <vs-td> {{ tr.paid_amount }} </vs-td>
              <vs-td> 1250 </vs-td>
              <vs-td> 1250 </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      required: true,
      type: Array,
    },
    from_date: {
      required: true,
    },
    to_date: {
      required: true,
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@/assets/scss/invoice.scss";
</style>
<style lang="scss">
@import "@/assets/scss/print.scss";
</style>