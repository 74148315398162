<template>
  <div id="invoice-page">
    <vx-card title="تقرير المعاملات المالية" title-color="primary">
      <div class="vx-row">
        <div class="vx-col w-1/2">
          <label class="vs-input--label"> نوع التقرير</label>
          <v-select
            name="name"
            class="w-full"
            data-vv-as="sku"
            data-vv-scope="order-items"
            label="type"
            :options="report_Types"
            v-model="selected_type"
            :tabindex="4"
            :filterable="true"
          ></v-select>
        </div>
      </div>
      <vs-divider position="left" textClass="text-size" color="primary">
        متغيرات التقرير
      </vs-divider>
      <div class="vx-row" v-if="selected_type">
        <div class="vx-col w-1/5" v-if="selected_type.id == 2">
          <label class="vs-input--label"> إسم المتجر</label>
          <v-select
            multiple
            name="stores"
            class="w-full"
            label="name"
            :options="stores"
            v-model="store_ids"
            :reduce="(item) => item.id"
            :filterable="true"
          ></v-select>
        </div>
        <div class="vx-col w-1/5" v-else>
          <label class="vs-input--label"> إسم العميل</label>
          <v-select
            multiple
            :options="users"
            :filterable="false"
            v-model="user_ids"
            :reduce="(item) => item.id"
            label="name"
          >
            <li slot="list-footer" class="pagination flex">
              <vs-button
                icon-pack="feather"
                icon="icon-arrow-left"
                type="border"
                :disabled="!prev_page_url"
                @click="fetchUsers(prev_page_url)"
                size="small"
              ></vs-button>
              <vs-button
                type="border"
                icon-pack="feather"
                icon="icon-arrow-right"
                :disabled="!next_page_url"
                @click="fetchUsers(next_page_url)"
                size="small"
              ></vs-button>
            </li>
          </v-select>
        </div>
        <div class="vx-col w-1/5">
          <label class="vs-input--label"> حالات المعاملة</label>
          <v-select
            multiple
            name="statuses"
            :options="transactionStatuses"
            v-model="status_ids"
            :tabindex="4"
            :reduce="(item) => item.value"
            :filterable="true"
          ></v-select>
        </div>
        <div class="vx-col w-1/5">
          <vs-input
            type="date"
            class="w-full"
            name="expire"
            v-model="from_date"
            v-validate="'required'"
            label="مـــن"
          />
        </div>
        <div class="vx-col w-1/5">
          <vs-input
            type="date"
            class="w-full"
            name="expire"
            v-model="to_date"
            v-validate="'required'"
            label="الى"
          />
        </div>
        <div class="vx-col w-1/5">
          <vs-button
            class="mb-base mr-3 mt-5"
            icon-pack="feather"
            @click="getReport"
            >إستخراج تقرير
          </vs-button>
        </div>
      </div>
    </vx-card>
    <div class="flex items-center justify-end mt-4" v-if="report_data.length">
      <vs-button
        class="mb-base mr-3"
        icon-pack="feather"
        icon="icon icon-file"
        @click="printInvoice"
        >طباعة</vs-button
      >
    </div>
    <vx-card v-if="report_data.length" class="mt-4" id="invoice-container">
      <transaction-report
        :data="report_data"
        :from_date="from_date"
        :to_date="to_date"
      ></transaction-report>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import transactionReport from "./components/transactions.vue";
export default {
  components: {
    vSelect,
    transactionReport,
  },
  data() {
    return {
      report_Types: [
        { id: 1, type: "عميل" },
        { id: 2, type: "متجر" },
      ],
      selected_type: null,
      current_Page: 1,
      next_page_url: null,
      prev_page_url: null,
      users: [],
      user_ids: null,
      status_ids: null,
      store_ids: null,
      to_date: "",
      from_date: "",
      report_data: [],
    };
  },
  computed: {
    stores() {
      return this.$store.state.stores.stores;
    },
  },
  methods: {
    ...mapActions("stores", ["fetchStores"]),
    ...mapActions("users", ["fetchEndUsers"]),
    ...mapActions("reports", ["fetchTransactionRequest"]),
    printInvoice() {
      window.print();
    },

    fetchUsers(page) {
      this.fetchEndUsers(page).then((response) => {
        this.users = response.data.data.data;
        this.current_Page = response.data.data.current_page;
        this.next_page_url = response.data.data.next_page_url
          ? response.data.data.next_page_url.split("=")[1]
          : null;
        this.prev_page_url = response.data.data.prev_page_url
          ? response.data.data.prev_page_url.split("=")[1]
          : null;
      });
    },
    getReport() {

      let bodyFormdata = new FormData();
      if (this.user_ids) {
        for (let i = 0; i <= this.user_ids.length; i++)
          bodyFormdata.set(`user_id[${i}]`, this.user_ids[i]);
      } else {
        for (let i = 0; i <= this.store_ids.length; i++)
          bodyFormdata.set(`store_id[${i}]`, this.store_ids[i]);
      }
      for (let i = 0; i <= this.status_ids.length; i++)
        bodyFormdata.set(`status[${i}]`, this.status_ids[i]);

      bodyFormdata.set("from_date", this.from_date);
      bodyFormdata.set("to_date", this.to_date);
      this.fetchTransactionRequest(bodyFormdata).then((response) => {
        this.report_data = response.data.data.Transactions.data;
        if (this.report_data.length) {
            this.$vs.notify({
              title: "تم بنجاح",
              text: "تم إستخراج التقرير بنجاح",
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: "عملية غير ناجحة",
              text: "إما إنه لا يوجد بيانات لهذا العميل أو إنه لا توجد بيانات للفترة الزمنية التي تم تحديدها.",
              color: "warning",
            });
          }
      });
    },
  },
  created() {
    this.fetchUsers(1);
    this.fetchStores();
  },
  mounted() {
    this.$emit("setAppClasses", "invoice-page");
  },
};
</script>

<style>
</style>
